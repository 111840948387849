<template>
<div class="select-component">
  <v-combobox
    v-model="internalValue"
    :placeholder="placeholder"
    :class="cssClass"
    :solo="solo"
    :multiple="multiple"
    append-icon="mdi-chevron-down"
    :item-text="type === 'employee' ? item => getFullname(item) : itemText"
    :item-value="itemValue"
    :return-object="returnObject"
    :items="itemsToShow"
    :menu-props="{ offsetY: true }"
    @change="handleChange"
    @input="handleInput"
    :dense="dense"
    hide-details
    :disabled="disabled"
    flat
  >
    <template v-slot:item="{ item }">
      <template v-if="itemsToShow.indexOf(item) === 0 && items.length > 1">
        <v-list-item v-show="!hideSelectAll" @click="selectAll">
          <v-list-item-action>
            <v-icon color="primary">mdi-check-all</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Выбрать все</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-icon v-if="isSelected(item)">mdi-checkbox-marked</v-icon>
        <span v-else class="d-flex">
          <span class="checkbox-square"></span>
        </span>
        <span class="ml-3 ft-14">{{ type === 'address' ? item[itemText] : getFullname(item) }}</span>
      </template>
    </template>
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0">
        {{ type === 'address' ? currentItems(item) : currentEmployee(item) }}
      </span>
      <span v-if="index === 1" class="grey--text text-caption">&nbsp;
        (+ еще {{ internalValue.length - 1 }})
      </span>
    </template>
  </v-combobox>
</div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Array, Object],
      default: null
    },
    type: {
      type: String,
      default: "address"
    },
    placeholder: {
      type: String,
      default: "Адрес"
    },
    cssClass: {
      type: String,
      default: "text-caption border-all"
    },
    solo: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    itemText: {
      type: String,
      default: "apartment_address"
    },
    itemValue: {
      type: String,
      default: "id"
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Function,
      default: () => {}
    },
    dense: {
      type: Boolean,
      default: true
    },
    hideSelectAll: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.internalValue = [];
      }
    }
  },
  data() {
    return {
      internalValue: this.initialInternalValue()
    }
  },
  computed: {
    itemsToShow() {
      if (this.items.length > 1) {
        return [{ id: '', [this.itemText]: 'Все' }, ...this.items];
      }
      return this.items;
    },
    isAllSelected() {
      return this.internalValue.length === this.items.length;
    }
  },
  methods: {
    selectAll() {
      if (this.isAllSelected) {
        this.internalValue = [];
      } else {
        this.internalValue = this.items.map(item => item.id);
      }
      this.$emit('select-all', this.internalValue);
    },
    initialInternalValue() {
      if (typeof this.value === 'string' && this.value.trim() === '') {
        return [];
      } else if (typeof this.value === 'string') {
        return [this.value];
      } else {
        return this.value;
      }
    },
    isSelected(item) {
      if (this.internalValue) {
        return this.internalValue.includes(item.id);
      }
    },
    handleChange() {
      console.log('this.internalValue', this.internalValue)
      this.$emit("change", this.internalValue);
    },
    handleInput() {
      this.$emit("input", this.internalValue);
    },
    currentItems(item) {
			if(item.length > 1) {
				const result = this.items.find(({ id }) => id === item);
				return result[this.itemText]
			}
		},
    currentEmployee(item) {
			if(item.length > 1) {
				const result = this.items.find(({ id }) => id === item);
				return  result?.surname  + ' ' + result?.name
			}
		},
  },
  created() {
    this.internalValue = this.initialInternalValue();
  },
};
</script>

<style scoped>
.checkbox-square {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #B5B9BD;
  border-radius: 2px;
  margin-left: 2px;
  margin-right: 2px;
}
</style>

<style>
.ft-14 {
  font-size: 14px;
}
</style>
